// extracted by mini-css-extract-plugin
export var header = "vwvds-module--header--n1TP2";
export var headerButton = "vwvds-module--headerButton--ws0cm";
export var headerImage01 = "vwvds-module--headerImage01--y8Wk4";
export var headerImage02 = "vwvds-module--headerImage02--UKHG6";
export var headerImage03 = "vwvds-module--headerImage03--Pn0IF";
export var bodyOne = "vwvds-module--bodyOne--Ot35t";
export var bodyOneImageLeft = "vwvds-module--bodyOneImageLeft--l6rl7";
export var bodyOneImageRight = "vwvds-module--bodyOneImageRight--ijJdE";
export var bodyOneImageManLeft = "vwvds-module--bodyOneImageManLeft--j6pAO";
export var bodyOneImageManRight = "vwvds-module--bodyOneImageManRight--KLiNZ";
export var bodyOneRight1 = "vwvds-module--bodyOneRight1--9HHUp";
export var bodyOneLeft1 = "vwvds-module--bodyOneLeft1--uaHvA";
export var bodyOneRight2 = "vwvds-module--bodyOneRight2--tXBFk";
export var bodyOneLeft2 = "vwvds-module--bodyOneLeft2--qENsC";
export var bodyOneHidden = "vwvds-module--bodyOneHidden--juz7X";
export var bodyOneMHidden = "vwvds-module--bodyOneMHidden--CSIfz";
export var bodyTwo = "vwvds-module--bodyTwo--nx2NA";
export var bodyTwoCenter = "vwvds-module--bodyTwoCenter--AHxKD";
export var bodyTwoImageArrow = "vwvds-module--bodyTwoImageArrow--VIP-A";
export var bodyTwoImageWoman = "vwvds-module--bodyTwoImageWoman--3w3Z5";
export var bodyThree = "vwvds-module--bodyThree--M47NM";
export var bodyThreeGridWrapper = "vwvds-module--bodyThreeGridWrapper--rqb7s";
export var bodyThreeGridProduct = "vwvds-module--bodyThreeGridProduct--thId7";
export var bodyThreeImageProduct = "vwvds-module--bodyThreeImageProduct--OA4YB";
export var bodyThreeGridProductLeft = "vwvds-module--bodyThreeGridProductLeft--J3f+1";
export var bodyThreeGridProductRight = "vwvds-module--bodyThreeGridProductRight--PtSSf";
export var bodyFour = "vwvds-module--bodyFour--C6RoY";
export var bodyFourLeft = "vwvds-module--bodyFourLeft--ORFRy";
export var bodyFourRight = "vwvds-module--bodyFourRight--onuQk";
export var bodyFive = "vwvds-module--bodyFive--jMzrC";
export var bodyFiveGridWrapper = "vwvds-module--bodyFiveGridWrapper--QBRS5";
export var bodyFiveGridLeft = "vwvds-module--bodyFiveGridLeft--QIQBV";
export var bodyFiveGridRight = "vwvds-module--bodyFiveGridRight--hkjdQ";
export var bodySix = "vwvds-module--bodySix--rKApe";
export var bodySixImageWoman = "vwvds-module--bodySixImageWoman--IjYaO";
export var bodySixHidden = "vwvds-module--bodySixHidden--5uYU5";
export var bodySeven = "vwvds-module--bodySeven--ytYJe";
export var bodySevenHeader = "vwvds-module--bodySevenHeader--bzdWA";
export var bodySevenMenu01 = "vwvds-module--bodySevenMenu01--8fI8+";
export var bodySevenMenuWrap = "vwvds-module--bodySevenMenuWrap--wjWT2";
export var bodySevenProduct = "vwvds-module--bodySevenProduct--5W6dQ";