import React, { useState } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "./vwvds.module.css"


const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const [val, setval] = useState([
    { name: "chk01", value: "" },
    { name: "chk02", value: "" },
    { name: "chk03", value: "" },
    { name: "chk04", value: "" },
  ])

  const handleFrequencyChange = (e) => {
    if(val.length === 0) {
      setval([...val, { name: e.target.name, value: e.target.value}])
    } else {
      const found = val.find(element => element.name === e.target.name)
      // console.log(found)
      if(found === undefined) {
        setval([...val, { name: e.target.name, value: e.target.value}])
      } else {
        const newValue02 =  (val.find(item => item.name ===  e.target.name).value === e.target.value ) ? "" : e.target.value
        let newval = val.filter(item => item.name !== e.target.name)
        newval.push( { name: e.target.name, value: newValue02})
        setval(newval)
      }
    }
    // console.log(val)
  }

  // ヤフー広告コンバージョン用コード
  if (typeof document !== `undefined`) {
    // ytag.jsをheadタグに埋め込み
    const script1 = document.createElement('script')
    script1.src = `https://s.yimg.jp/images/listing/tool/cv/ytag.js`
    script1.async = true
    document.head.appendChild(script1)
    
    // 実行用scriptをheadタグに埋め込み
    const script2 = document.createElement('script')
    script2.text = `window.yjDataLayer = window.yjDataLayer || [];
    function ytag() { yjDataLayer.push(arguments); }
    ytag({"type":"ycl_cookie"});`
    document.head.appendChild(script2)
  }
  // リンククリックした時のコンバージョン
  const ConversionVWVDS6200 = () => {
    // Google広告のコンバージョン
    typeof window !== "undefined" &&
    window.gtag("event", "conversion", {
      send_to: "AW-597524462/P1E4CO31__0CEO7_9ZwC",
    })
    // Yahoo広告のコンバージョン
    if (typeof document !== `undefined`) {
      // 実行用scriptをbodyタグに埋め込み
        const scriptContact = document.createElement('script')
        scriptContact.text = `
        ytag({
          "type": "yss_conversion",
          "config": {
            "yahoo_conversion_id": "1001162645",
            "yahoo_conversion_label": "jmewCLjY6P0CEIqA-pQC",
            "yahoo_conversion_value": "100"
          }
        });`;
        document.body.appendChild(scriptContact)
    }
  }
  const ConversionVWVDS6500 = () => {
    // Google広告のコンバージョン
    typeof window !== "undefined" &&
      window.gtag("event", "conversion", {
        send_to: "AW-597524462/6XnnCKzIp_4CEO7_9ZwC",
      })
    // Yahoo広告のコンバージョン
    if (typeof document !== `undefined`) {
      // 実行用scriptをbodyタグに埋め込み
        const scriptContact = document.createElement('script')
        scriptContact.text = `
        ytag({
          "type": "yss_conversion",
          "config": {
            "yahoo_conversion_id": "1001162645",
            "yahoo_conversion_label": "SXP0CNnY6P0CEIqA-pQC",
            "yahoo_conversion_value": "100"
          }
        });`;
        document.body.appendChild(scriptContact)
    }
  }
  const ConversionVWVDS5030 = () => {
    // Google広告のコンバージョン
    typeof window !== "undefined" &&
      window.gtag("event", "conversion", {
        send_to: "AW-597524462/oVYwCLeyrP4CEO7_9ZwC",
      })
    // Yahoo広告のコンバージョン
    if (typeof document !== `undefined`) {
      // 実行用scriptをbodyタグに埋め込み
        const scriptContact = document.createElement('script')
        scriptContact.text = `
        ytag({
          "type": "yss_conversion",
          "config": {
            "yahoo_conversion_id": "1001162645",
            "yahoo_conversion_label": "voH2COHuvP0CEIqA-pQC",
            "yahoo_conversion_value": "100"
          }
        });`;
        document.body.appendChild(scriptContact)
    }
  }
  const ConversionVWVDS2700 = () => {
    // Google広告のコンバージョン
    typeof window !== "undefined" &&
      window.gtag("event", "conversion", {
        send_to: "AW-597524462/lWDfCMjPp_4CEO7_9ZwC",
      })
    // Yahoo広告のコンバージョン
    if (typeof document !== `undefined`) {
      // 実行用scriptをbodyタグに埋め込み
        const scriptContact = document.createElement('script')
        scriptContact.text = `
        ytag({
          "type": "yss_conversion",
          "config": {
            "yahoo_conversion_id": "1001162645",
            "yahoo_conversion_label": "B5L2CO7h6P0CEIqA-pQC",
            "yahoo_conversion_value": "100"
          }
        });`;
        document.body.appendChild(scriptContact)
    }
  }

  const ConversionContact = () => {
    // Google広告のコンバージョン
    typeof window !== "undefined" &&
      window.gtag("event", "conversion", {
        send_to: "AW-597524462/dGzRCIe1rP4CEO7_9ZwC",
      })
    // Yahoo広告のコンバージョン
    if (typeof document !== `undefined`) {
      // 実行用scriptをbodyタグに埋め込み
        const scriptContact = document.createElement('script')
        scriptContact.text = `
        ytag({
          "type": "yss_conversion",
          "config": {
            "yahoo_conversion_id": "1001162645",
            "yahoo_conversion_label": "TxpOCNaD5f0CEIqA-pQC",
            "yahoo_conversion_value": "100"
          }
        });`;
        document.body.appendChild(scriptContact)
    }
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="VDSシリーズ" description="同軸ケーブルの用途拡大。映像、電源をワンケーブルにまとめて省配線化、LANケーブルの代用可能"/>
      <div className={styles.header}>
        <p className={styles.headerButton}><span>VDSシリーズ</span></p>
        <h1>映像・音声・接点・<br/>電源重畳長距離電送器</h1>
        <p>同軸ケーブルの用途拡大<br/>映像、電源をワンケーブルにまとめて省配線化、LANケーブルとして代用することも</p>
        <Link to="/contact/?subject=電源重畳に関するお問い合わせ" onClick={ConversionContact}><button>設置の相談をする</button></Link>
      <StaticImage className={styles.headerImage01}
        src="./images/vwvds/header-vwvds-001.png"
        width={180}
      />
      <StaticImage className={styles.headerImage02}
        src="./images/vwvds/header-vwvds-002.png"
        width={180}
      />
      <StaticImage className={styles.headerImage03}
        src="./images/vwvds/header-vwvds-003.png"
        width={180}
      />
      </div>
      <div className={styles.bodyOne}>
        <StaticImage className={styles.bodyOneImageManLeft}
            src="./images/vwvds/man-left.png"
            height={250}
            />
        <StaticImage className={styles.bodyOneImageManRight}
            src="./images/vwvds/man-right.png"
            width={150}
            height={270}
        />
        <h1>
        <StaticImage className={styles.bodyOneImageLeft}
          src="./images/vwvds/blue-line-left.svg"
          width={30}
        />
          こんなことで<br className={styles.bodyOneHidden}/>お困りではありませんか？
        <StaticImage className={styles.bodyOneImageRight}
          src="./images/vwvds/blue-line-right.svg"
          width={30}
        />
        </h1>
        <div className={styles.bodyOneRight1}>
          <p>防犯カメラを取り付けたいけど<br/><span>映像と電源の2つも配線するのが
            <br className={styles.bodyOneHidden}/>めんどくさい</span></p>
        </div>
        <div className={styles.bodyOneLeft1}>
          <p>映像だけじゃなくて音声も
            <br className={styles.bodyOneHidden}/>伝送したいけど、
            <br className={styles.bodyOneMHidden}/>
            <span>同軸ケーブルが<br className={styles.bodyOneHidden}/>1本しか配線されていない</span></p>
        </div>
        <div className={styles.bodyOneRight2}>
          <p>カメラをもう1台増設したい<br className={styles.bodyOneHidden}/>けど、
          <br className={styles.bodyOneMHidden}/><span>コストが高い</span>から既設の<br className={styles.bodyOneHidden}/>
          同軸ケーブルで<br className={styles.bodyOneMHidden}/>2台分の<br className={styles.bodyOneHidden}/>カメラ映像を伝送したい</p>
        </div>
        <div className={styles.bodyOneLeft2}>
          <p>アナログカメラから<br className={styles.bodyOneHidden}/>IPカメラに変更したいけど<br/>
          <span>同軸ケーブルをLANケーブルに<br className={styles.bodyOneHidden}/>変えたくない</span></p>
        </div>
      </div>
      <div className={styles.bodyTwo}>
        <div className={styles.bodyTwoCenter}>
          <StaticImage className={styles.bodyTwoImageArrow}
              src="./images/vwvds/blue-arrow.svg"
          />
        </div>
          <StaticImage className={styles.bodyTwoImageWoman}
              src="./images/vwvds/woman-point.png"
              width={250}
          />
        <h1>VWVDSシリーズで<br/><span>すべて解決できます！</span></h1>
      </div>
      <div className={styles.bodyThree}>
        <h1>高機能 電源重畳ユニット <br className={styles.bodyOneHidden}/>VWVDSシリーズで出来ること</h1>
        <hr/>
        <div className={styles.bodyThreeGridWrapper}>
          <div className={styles.bodyThreeGridProduct}>
            <div className={styles.bodyThreeGridProductLeft}>
              <StaticImage className={styles.bodyThreeImageProduct} 
                src="./images/vwvds/items-vwvds-6200.png"
              />
              <a href="../../page/miscs/miscs-vds6200" onClick={ConversionVWVDS6200}><button>VWVDS-6200</button></a>
            </div>
            <div className={styles.bodyThreeGridProductRight}>
              <p>同軸ケーブル1本で映像とカメラの電源を伝送が可能</p>
              <p><span><StaticImage src="./images/vwvds/icon-light.svg" height={24} />こんな方におすすめ</span></p>
              <p>カメラを設置するのに同軸ケーブルとカメラ電源用ケーブルの2本を配線するのは大変。同軸ケーブル1本だけでどちらも伝送したい</p>
            </div>
          </div>
          <div className={styles.bodyThreeGridProduct}>
            <div className={styles.bodyThreeGridProductLeft}>
              <StaticImage className={styles.bodyThreeImageProduct} 
                src="./images/vwvds/items-vwvds-6500.png"
              />
              <a href="../../page/miscs/miscs-vds6500" onClick={ConversionVWVDS6500}><button>VWVDS-6500</button></a>
            </div>
            <div className={styles.bodyThreeGridProductRight}>
              <p>同軸ケーブルをLANケーブルの代わりに利用可能。IPカメラの映像を同軸ケーブルで伝送可能に</p>
              <p><span><StaticImage src="./images/vwvds/icon-light.svg" height={24} />こんな方におすすめ</span></p>
              <p>カメラを設置するのに同軸ケーブルとカメラ電源用ケーブルの2本を配線するのは大変。同軸ケーブル1本だけでどちらも伝送したい</p>
            </div>
          </div>
          <div className={styles.bodyThreeGridProduct}>
            <div className={styles.bodyThreeGridProductLeft}>
              <StaticImage className={styles.bodyThreeImageProduct} 
                src="./images/vwvds/items-vwvds-5030.png"
                />
              <a href="../../page/miscs/miscs-vwvdl5030" onClick={ConversionVWVDS5030}><button>VWVDS-5030</button></a>
            </div>
            <div className={styles.bodyThreeGridProductRight}>
              <p>VWVDS-5030なら同軸ケーブルをLANケーブルの代わりに利用可能。IPカメラの映像を同軸ケーブルで伝送可能に</p>
              <p><span>
                <StaticImage src="./images/vwvds/icon-light.svg" height={24} />こんな方におすすめ</span></p>
              <p>カメラをアナログカメラからIPカメラにしたいけど、同軸ケーブルからLANケーブルに配線しなおすとなると費用が高い</p>
            </div>
          </div>
          <div className={styles.bodyThreeGridProduct}>
            <div className={styles.bodyThreeGridProductLeft}>
              <StaticImage className={styles.bodyThreeImageProduct} 
                  src="./images/vwvds/items-vwvds-2700.png"
                  />
              <a href="../../page/miscs/miscs-vwvds-2700" onClick={ConversionVWVDS2700}><button>VWVDS-2700</button></a>
            </div>
            <div className={styles.bodyThreeGridProductRight}>
              <p>映像と電源に加えて音声も同軸ケーブル１本で伝送が可能</p>
              <p><span><StaticImage src="./images/vwvds/icon-light.svg" height={24} />こんな方におすすめ</span></p>
              <p>カメラの映像と電源だけじゃなくて音声も一緒に送りたい</p>
            </div>
          </div>

        </div>
      </div>
      <div className={styles.bodyFour}>
        <div className={styles.bodyFourLeft}>
          <StaticImage src="./images/vwvds/outside-camera-long.png" height={280} />
        </div>
        <div className={styles.bodyFourRight}>
          <h1>導入実績</h1>
          <hr/>
          <p>大手放送局、大手企業、病院など…</p>
          <h2><StaticImage src="./images/vwvds/red-line-left.svg" height={20} />実績多数<StaticImage src="./images/vwvds/red-line-right.svg" height={20} /></h2>
          <Link to="/contact/?subject=電源重畳に関するお問い合わせ" onClick={ConversionContact}><button>設置の相談をする</button></Link>
        </div>
      </div>
      <div className={styles.bodyFive}>
        <h1>これまでにご利用いただいた方の例</h1>
        <hr/>
        <div  className={styles.bodyFiveGridWrapper}>
          <div className={styles.bodyFiveGridLeft}>
            <p><StaticImage src="./images/vwvds/case-001.png" /></p>
          </div>
          <div className={styles.bodyFiveGridRight}>
            <h2><StaticImage src="./images/vwvds/icon-man.svg" height={22} />&nbsp;お客様のご要望</h2>
            <p>牧場で牛舎内を映像と音声で監視したいけど、同軸ケーブルが1本だけで地中に埋まってしまっているので配線が出来ないからそのまま利用したい</p>
            <h4><StaticImage src="./images/vwvds/gray-arrow.svg" /></h4>
            <h3><StaticImage src="./images/vwvds/icon-light-white.svg" height={22} />&nbsp;解決した方法</h3>
            <p>VWVDS-2700を使用して牛舎と監視場所間を既設の同軸ケーブル1本で映像と音声伝送を提案。さらにレコーダーと組み合わせる事でモニタに映像表示やインターネット経由で遠隔監視も</p>
          </div>
          <div className={styles.bodyFiveGridLeft}>
            <p><StaticImage src="./images/vwvds/case-002.png" /></p>
          </div>
          <div className={styles.bodyFiveGridRight}>
            <h2><StaticImage src="./images/vwvds/icon-man.svg" height={22} />&nbsp;お客様のご要望</h2>
            <p>カメラをもう一台増設したいけど、マンションで管路が狭くて同軸ケーブルを2本通す事が出来ない場所があって増設が出来ないのを解決したい</p>
            <h4><StaticImage src="./images/vwvds/gray-arrow.svg" /></h4>
            <h3><StaticImage src="./images/vwvds/icon-light-white.svg" height={22} />&nbsp;解決した方法</h3>
            <p>VDS6500を使えば2つの映像を１つの同軸ケーブルで伝送できるので同軸ケーブルを増設しなくてもカメラを追加設置可能。映像と一緒に電源も伝送出来るので電源ケーブルの配線も不要</p>
          </div>
          <div className={styles.bodyFiveGridLeft}>
            <p><StaticImage src="./images/vwvds/case-003.png" /></p>
          </div>
          <div className={styles.bodyFiveGridRight}>
            <h2><StaticImage src="./images/vwvds/icon-man.svg" height={22} />&nbsp;お客様のご要望</h2>
            <p>カメラをアナログカメラからIPカメラにしたいけど、同軸ケーブルからLANケーブルに配線しなおすとなると費用が高い。どうにかして安く済ませたい</p>
            <h4><StaticImage src="./images/vwvds/gray-arrow.svg" /></h4>
            <h3><StaticImage src="./images/vwvds/icon-light-white.svg" height={22} />&nbsp;解決した方法</h3>
            <p>VWVDS-8500を使えば同軸ケーブルをLANケーブルとして使用できるのでカメラをIPカメラに交換するだけでＯＫ。同軸ケーブルをLANケーブルに交換する費用を節約！！</p>
          </div>
        </div>
      </div>
      <div className={styles.bodySix}>
        <h1>用途に合わせて<br className={styles.bodySixHidden}/>最適な装置をお選びします</h1>
        <StaticImage className={styles.bodySixImageWoman}
              src="./images/vwvds/woman-pen.png"
          />
      </div>
      <div className={styles.bodySeven}>
        <div className={styles.bodySevenHeader}>
          <h1>簡単なアンケートにお答えください。</h1>
        </div>
        <div className={styles.bodySevenMenuWrap}>
          <p>Q.映像源はIPカメラですか？</p>
          <div className={styles.bodySevenMenu01}>
            <input 
              type="checkbox" 
              id="menu01-y" 
              name="chk01"
              value="menu01-y"
              checked={val.find(item => item.name ===  "chk01").value === "menu01-y"}
              onChange={handleFrequencyChange}
              />
            <label htmlFor="menu01-y">はい</label>
            <input 
              type="checkbox" 
              id="menu01-n" 
              name="chk01"
              value="menu01-n"
              checked={val.find(item => item.name ===  "chk01").value === "menu01-n"}
              onChange={handleFrequencyChange}
            />
            <label htmlFor="menu01-n">いいえ</label>
          </div>
        </div>
        { val.find(item => item.name ===  "chk01").value === "menu01-y" && 
          val.find(item => item.name ===  "chk02").value === "menu02-y" &&
          <div className={styles.bodySevenMenuWrap}>
            <p>Q.複数台のIPカメラをHUB経由で伝送しますか？</p>
            <div className={styles.bodySevenMenu01}>
              <input 
                type="checkbox" 
                id="menu02-y" 
                name="chk02"
                value="menu02-y"
                checked={ val.find(item => item.name ===  "chk02").value === "menu02-y"}
                onChange={handleFrequencyChange}
              />
              <label htmlFor="menu02-y">はい</label>
              <input 
                type="checkbox" 
                id="menu02-n" 
                name="chk02"
                value="menu02-n"
                checked={val.find(item => item.name ===  "chk02").value === "menu02-n"}
                onChange={handleFrequencyChange}
              />
              <label htmlFor="menu02-n">いいえ</label>
            </div>
          </div>
        
        }
        { val.find(item => item.name ===  "chk01").value === "menu01-n" && 
        <div className={styles.bodySevenMenuWrap}>
          <p>Q.複数台のアナログカメラをを1本の同軸ケーブルで伝送したいですか？</p>
          <div className={styles.bodySevenMenu01}>
            <input 
              type="checkbox" 
              id="menu03-y" 
              name="chk03"
              value="menu03-y"
              checked={val.find(item => item.name ===  "chk03").value === "menu03-y"}
              onChange={handleFrequencyChange}
            />
            <label htmlFor="menu03-y">はい</label>
            <input 
              type="checkbox" 
              id="menu03-n" 
              name="chk03"
              value="menu03-n"
              checked={val.find(item => item.name ===  "chk03").value === "menu03-n"}
              onChange={handleFrequencyChange}
            />
            <label htmlFor="menu03-n">いいえ</label>
          </div>
        </div>
        }
        { val.find(item => item.name ===  "chk01").value === "menu01-n" && 
          val.find(item => item.name ===  "chk03").value === "menu03-n" &&
          <div className={styles.bodySevenMenuWrap}>
            <p>Q.映像だけでなく音声も伝送したいですか？</p>
            <div className={styles.bodySevenMenu01}>
              <input 
                type="checkbox" 
                id="menu04-y" 
                name="chk04"
                value="menu04-y"
                checked={val.find(item => item.name ===  "chk04").value === "menu04-y"}
                onChange={handleFrequencyChange}
              />
              <label htmlFor="menu04-y">はい</label>
              <input 
                type="checkbox" 
                id="menu04-n" 
                name="chk04"
                value="menu04-n"
                checked={val.find(item => item.name ===  "chk04").value === "menu04-n"}
                onChange={handleFrequencyChange}
              />
              <label htmlFor="menu04-n">いいえ</label>
            </div>
          </div>
        }
        <div  className={styles.bodySevenProduct}>
          {/* <p>選択値：{val.join(', ')}</p> */}
          { val.find(item => item.name ===  "chk01").value === "menu01-y" && 
            <>
              <h3>最適な商品はこちら</h3>
              <StaticImage className={styles.bodyThreeImageProduct} 
                src="./images/vwvds/items-vwvds-5030.png"
                />
              <p><a href="../../page/miscs/miscs-vwvdl5030" onClick={ConversionVWVDS5030}><button>VWVDS-5030</button></a></p>
            </>
          }
          { val.find(item => item.name ===  "chk01").value === "menu01-n" && 
            val.find(item => item.name ===  "chk03").value === "menu03-n" &&
            val.find(item => item.name ===  "chk04").value === "menu04-y" &&
            <>
              <h3>最適な商品はこちら</h3>
              <StaticImage className={styles.bodyThreeImageProduct} 
                  src="./images/vwvds/items-vwvds-2700.png"
                  />
              <p><a href="../../page/miscs/miscs-vwvds-2700" onClick={ConversionVWVDS2700}><button>VWVDS-2700</button></a></p>
            </>
          }
          { val.find(item => item.name ===  "chk01").value === "menu01-n" && 
            val.find(item => item.name ===  "chk03").value === "menu03-n" &&
            val.find(item => item.name ===  "chk04").value === "menu04-n" &&
            <>
              <h3>最適な商品はこちら</h3>
              <StaticImage className={styles.bodyThreeImageProduct} 
                src="./images/vwvds/items-vwvds-6200.png"
              />
              <p><a href="../../page/miscs/miscs-vds6200" onClick={ConversionVWVDS6200}><button>VWVDS-6200</button></a></p>
            </>
          }
          { val.find(item => item.name ===  "chk01").value === "menu01-n" && 
            val.find(item => item.name ===  "chk03").value === "menu03-y" &&
            <>
              <h3>最適な商品はこちら</h3>
              <StaticImage className={styles.bodyThreeImageProduct} 
                src="./images/vwvds/items-vwvds-6500.png"
              />
              <p><a href="../../page/miscs/miscs-vds6500" onClick={ConversionVWVDS6500}><button>VWVDS-6500</button></a></p>
            </>
          }
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`